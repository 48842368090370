




























































import CategoryTreeviewComponent from "@/components/CategoryTreeviewComponent.vue"
import {Vue, Component, PropSync, VModel} from 'vue-property-decorator'
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule";

@Component({
    components: {CategoryTreeviewComponent}
})
export default class ProductFiltersDialog extends Vue {

    @PropSync("dialog") syncedDialog!: boolean
    @PropSync("hasVideos") syncedHasVideos!: Nullable<boolean>
    @PropSync("featured") syncedIsFeatured!: Nullable<boolean>
    @PropSync("enabled") syncedEnabled!: Nullable<boolean>
    @PropSync("blocked") syncedBlocked!: Nullable<boolean>
    @PropSync("uncategorized") syncedUncategorized!: Nullable<boolean>

    get lang() { return getModule(LangModule).lang }
    categoryTreeviewModule = getModule(CategoryTreeviewModule)
    get productCategories() { return this.categoryTreeviewModule.productCategories }
    get selectedCategories() { return this.categoryTreeviewModule.selectedCategories }
    set selectedCategories(categoryIds: number[]) { this.categoryTreeviewModule.setSelectedCategories(categoryIds) }

    refresh() {
        this.$emit("refresh")
    }

    clearFilter() {
        this.syncedEnabled = null
        this.syncedIsFeatured = null
        this.syncedHasVideos = null
        this.selectedCategories.splice(0, this.selectedCategories.length)
        this.refresh()
    }

}
